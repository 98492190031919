import type { MutableRefObject } from 'react';
import { useRef } from 'react';
import { usePrevious } from 'ahooks';
import type { Location } from 'history';

export interface LocationState {
    url: string;
    previousUrl?: string;
}
/**
 * Gives a ref object that contains the current
 * and the previous url.
 *
 * Made for use with ArchiPro Pixel
 *
 */
export const useLocationState = (
    location: Location,
    onChange?: (location: LocationState) => void
): MutableRefObject<LocationState> => {
    const firedOnMount = useRef(false);

    const previousLocation = usePrevious(location, (prev, next) => {
        const initialMount = typeof prev === 'undefined';

        const handleChangeEvent = () => {
            // SSR does not fire events
            // usePrevious does run it through anyway
            if (typeof document === 'undefined') {
                return;
            }

            onChange?.({
                url: getHref(next),
                previousUrl: prev && getHref(prev),
            });
        };

        if (initialMount) {
            if (!firedOnMount.current) {
                handleChangeEvent();
                firedOnMount.current = true;
            }
            return true;
        }

        const searchParamsChanged = next.search !== prev.search;
        const pathnameChanged = next.pathname !== prev.pathname;

        const changed = pathnameChanged || searchParamsChanged;

        if (changed) {
            handleChangeEvent();
        }

        return changed;
    });

    const locationState = useRef({
        url: getHref(location),
        previousUrl: previousLocation ? getHref(previousLocation) : undefined,
    });

    locationState.current.url = getHref(location);

    locationState.current.previousUrl = previousLocation
        ? getHref(previousLocation)
        : undefined;

    return locationState;
};

const getHref = (location: Location): string => {
    return `${location.pathname}${location.search}`;
};
